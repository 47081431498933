@tailwind base;
@tailwind components;
@tailwind utilities;

/* FOR MOBILE RESPONSIVE */
@media (min-width: 400px) and (max-width: 767px) {
  .mobile-width {
    width: 383px !important;
  }
  .ml-192 {
    margin-left: 192px !important;
  }
  .banner-carousel img {
    width: 383px;
  }
  .left-panel-replace .content div,
  .left-panel-replace .content div img {
    width: 383px;
  }
  .ml-190 {
    margin-left: 190px !important;
  }
  .left-190 {
    left: 190px !important;
  }
  .left-13rem {
    left: 13rem !important;
  }
  .left-350px {
    left: 355px !important;
  }
  .ml-29 {
    margin-left: 29px;
  }
  .ml-7px {
    margin-left: 7px;
  }
  .mobile-navbar {
    width: 300px !important;
    gap: 15px !important;
  }
  .py-30px {
    padding-left: 30px;
    padding-right: 30px;
  }
  .contact-mobile .ml-198 {
    left: -198px !important;
    position: relative;
  }
  .MuiDialog-paper {
    width: 383px !important;
    height: 60dvh !important;
  }
  .my-fructidor-tabs > span:first-child {
    margin-left: 365px;
  }
}
@media (min-width: 0px) and (max-width: 399px) {
  .mobile-navbar {
    left: 75px !important;
  }
  .mobile-width {
    width: 338px !important;
  }
  .banner-carousel img {
    width: 338px;
  }
  .left-panel-replace .content div,
  .left-panel-replace .content div img {
    width: 338px;
  }
  .contact-mobile .ml-198 {
    left: -215px !important;
    position: relative;
  }
  .MuiDialog-paper {
    width: 338px !important;
    height: 60dvh !important;
  }
  .my-fructidor-tabs > span:first-child {
    margin-left: 400px;
  }
}
/* FOR MOBILE RESPONSIVE */
@media (min-width: 0px) and (max-width: 767px) {
  .nav-btn {
    border: none;
    background-color: transparent;
  }
  .pr-10 {
    padding-right: 10% !important;
  }
  .pl-10 {
    padding-left: 5%;
  }
}

@media (min-width: 0px) and (max-width: 768px) {
  .mobile-nav-bar {
    display: block;
  }
  .desktop-nav-bar {
    display: none;
  }
  .login-icon {
    display: block;
  }
  .left-panel-replace {
    display: block;
  }
  .left-panel-replace .content {
    display: block;
    gap: 20px;
  }
  .contact-mobile .left-13rem {
    left: 0 !important;
  }
  .contact-mobile .left-190 {
    left: 0 !important;
  }
}
@media (min-width: 768px) {
  .language-dropdown .language-text {
    display: block;
  }
  .mobile-nav-bar {
    display: none;
  }
  .desktop-nav-bar {
    display: block;
  }
  .login-icon {
    display: none;
  }
  .left-panel-replace {
    display: none;
  }
}
/* signup */

.login-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
}
.image-container {
  width: 55%;
  background-size: cover;
  background-position: center;
  position: relative;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.form-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.image-container-pricing {
  width: 20%;
  background-size: cover;
  background-position: center;
  position: relative;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.form-container-pricing {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bg-desktop {
  background-image: url('https://ubqfructidorprod.blob.core.windows.net/prod-fructidor/website_static_images/login_background_3.png');
}
.bg-ipad {
  background-image: url('https://ubqfructidorprod.blob.core.windows.net/prod-fructidor/website_static_images/login_ipad_background.png');
}
.mask-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80%;
}
.login-testimonial {
  position: absolute;
  left: 15%;
  top: 200px;
}
.login-testimonial .description {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  width: 90%;
  color: #222;
  margin-bottom: 15px !important;
}
.login-testimonial .author {
  font-size: 16px;
  font-weight: normal;
  color: #6f6f6f;
  width: 90%;
  text-align: end;
}

@media (max-width: 1024px) {
  .login-wrapper {
    flex-direction: column-reverse;
  }
  .form-container {
    height: 75%;
    width: 100%;
  }
  .image-container {
    height: 25%;
    background-size: 100% 100%;
    width: 100%;
  }
  .form-container-pricing {
    width: 100% !important;
  }
  .image-container-pricing {
    height: 25%;
    background-size: 100% 100%;
    width: 100%;
  }
  .mask-image {
    display: none;
  }
}
@media (max-width: 1500px) {
  .form-container-pricing {
    width: 78%;
  }
  .image-container-pricing {
    width: 22%;
    background-position: top right;
  }
}
@media (min-width: 1500px) {
  .form-container-pricing {
    width: 70%;
  }
  .image-container-pricing {
    width: 30%;
    background-position: top right;
  }
}
@media (max-width: 767px) {
  .login-testimonial {
    top: 20px;
    text-align: center;
    left: 0;
    padding: 10px;
  }
  .image-container {
    display: none;
  }
  .form-container {
    height: 100%;
    width: 100%;
  }
  .image-container-pricing {
    display: none;
  }
  .form-containe-pricing {
    height: 100%;
    width: 100%;
  }
  .login-testimonial .description {
    width: 100%;
  }
}

@media (min-width: 0px) and (max-width: 400px) {
  .login-testimonial {
    top: 20px;
    text-align: center;
    left: 0;
  }
  .login-testimonial .description {
    font-size: 14px;
  }
  .image-container {
    display: none;
  }
  .form-container {
    width: 100%;
  }
  .image-container-pricing {
    display: none;
  }
  .form-container-pricing {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .login-testimonial {
    text-align: center;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 90px;
  }
  .login-testimonial .description {
    width: 80%;
  }
  .bg-ipad {
    display: block !important;
  }
  .bg-desktop {
    display: none;
  }
}
@media (min-width: 1025px) {
  .bg-ipad {
    display: none;
  }
  .bg-desktop {
    display: block;
  }
}
@media (min-width: 991px) and (max-width: 1024px) {
  .login-testimonial {
    text-align: center;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 100px;
  }
  .login-testimonial .description {
    width: 80%;
    font-size: 23px;
  }
}
